import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../common';
import countries from '../OfferPortal/countries';
import { Input, Tooltip, Button, notification } from 'antd';
import { useAuthContext } from '../../../utils';

const { TextArea } = Input;

const Broadcasting = () => {
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('All');
  const [broadcastMessage, setBroadcastMessage] = useState('');
  const [broadcastStatuses, setBroadcastStatuses] = useState({});
  const { superAdmin } = useAuthContext();

  const fetchAgencies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAgenciesByLocation`,
        {
          params: {
            country: selectedCountry,
            region: selectedCity === 'All' ? '' : selectedCity,
          },
        }
      );
      setVerifiedAgencies(response.data.verifiedAgencies);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = value => {
    setSelectedCountry(value);

    if (value === 'All') {
      const allCities = countries.reduce((acc, country) => {
        return [...acc, ...country.cities];
      }, []);
      setCities(['All', ...allCities]);
    } else {
      const country = countries.find(c => c.value === value);
      setCities(country ? ['All', ...country.cities] : []);
    }
    setSelectedCity('All');
  };

  const handleCityChange = value => {
    setSelectedCity(value);
  };

  const sendMessageToAgency = async agency => {
    const message = {
      receiverId: agency._id,
      content: broadcastMessage,
      timestamp: new Date().toISOString(),
      senderModel: 'User',
      receiverModel: agency.agency ? 'Agency' : 'User',
    };

    try {
      // Simulate adding the message locally (optional)
      setBroadcastStatuses(prev => ({ ...prev, [agency._id]: 'pending' }));

      // Send the message
      await axios.post(
        `${process.env.REACT_APP_API_URL}/messages/send`,
        message
      );

      setBroadcastStatuses(prev => ({ ...prev, [agency._id]: 'success' }));
    } catch (error) {
      console.error(`Error sending message to ${agency.name}:`, error);
      setBroadcastStatuses(prev => ({ ...prev, [agency._id]: 'failed' }));
    }
  };

  const handleSendBroadcast = async () => {
    if (!broadcastMessage.trim()) {
      notification.warning({
        message: 'Message Required',
        description: 'Please write a broadcast message before sending.',
      });
      return;
    }

    for (const agency of verifiedAgencies) {
      await sendMessageToAgency(agency); // Send message sequentially
    }

    notification.success({
      message: 'Broadcast Completed',
      description: 'Broadcast process has been completed for all agencies.',
    });
  };

  useEffect(() => {
    fetchAgencies();
  }, [selectedCity, selectedCountry]);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ marginBottom: '20px' }}>Broadcasting Agencies</h2>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          marginBottom: '20px',
          justifyContent: 'center',
        }}
      >
        <Dropdown
          options={['All', ...countries.map(country => country.value)]}
          placeholder='Select a country'
          value={selectedCountry}
          onChange={handleCountryChange}
        />
        <Dropdown
          options={cities}
          placeholder='Select a city'
          value={selectedCity}
          onChange={handleCityChange}
          disabled={selectedCountry === 'All'}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <h3 style={{ marginBottom: '10px' }}>Broadcast Message</h3>
        <TextArea
          rows={4}
          value={broadcastMessage}
          onChange={e => setBroadcastMessage(e.target.value)}
          placeholder='Write your message here...'
          maxLength={500}
          style={{
            resize: 'none',
            borderRadius: '5px',
            border: '1px solid #ddd',
            padding: '10px',
          }}
        />
        <p style={{ marginTop: '5px', color: '#888', fontSize: '12px' }}>
          {broadcastMessage.length} / 500 characters
        </p>
        <Button
          type='primary'
          style={{ marginTop: '10px' }}
          onClick={handleSendBroadcast}
          disabled={loading || verifiedAgencies.length === 0 || !superAdmin}
        >
          Send
        </Button>
      </div>
      <div
        style={{
          color: '#333',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: 32,
        }}
      >
        {verifiedAgencies.map(agency => (
          <Tooltip
            key={agency._id}
            title={
              <>
                <p>
                  <strong>Name:</strong> {agency.name || 'Unknown'}
                </p>
                <p>
                  <strong>Phone:</strong> {agency.phone || 'N/A'}
                </p>
                <p>
                  <strong>Email:</strong> {agency.email || 'N/A'}
                </p>
                <p>
                  <strong>Address:</strong> {agency.address?.full || 'N/A'}
                </p>
              </>
            }
          >
            <span
              style={{
                padding: '5px 10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                backgroundColor:
                  broadcastStatuses[agency._id] === 'success'
                    ? '#d4edda'
                    : broadcastStatuses[agency._id] === 'failed'
                    ? '#f8d7da'
                    : '#f9f9f9',
                color:
                  broadcastStatuses[agency._id] === 'success'
                    ? '#155724'
                    : broadcastStatuses[agency._id] === 'failed'
                    ? '#721c24'
                    : '#333',
              }}
            >
              {agency.name || 'Unknown'}
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default Broadcasting;
