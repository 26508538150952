import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext({
  user: {},
  token: '',
  isAuthenticated: false,
  loading: true, // Add a loading state
  setUser: () => {},
  setToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(token));
  const [loading, setLoading] = useState(true); // Track loading status
  const superAdmin = user.email === 'oooytun@icloud.com'

  useEffect(() => {
    // Set headers when the token changes
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      localStorage.setItem('token', token);
    } else {
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('token');
      setIsAuthenticated(false); // Reset authentication state if token is missing
    }
  }, [token]);

  useEffect(() => {
    const loginSilently = async () => {
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/loginWithToken`,
            { token }
          );
          setUser(response.data.user);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Silent login failed:', error);
          setToken(''); // Clear token if login fails
          setIsAuthenticated(false);
        }
      }
      setLoading(false); // Mark loading as complete
    };

    loginSilently();
  }, [token]);

  return (
    <AuthContext.Provider
      value={{ user, setUser, token, setToken, isAuthenticated, loading, superAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
