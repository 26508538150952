import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './authContext';

const ProtectedRoute = ({ children }) => {
  const { token, loading } = useAuthContext();

  // Wait for the auth context to finish loading
  if (loading) {
    return null; // Optionally show a loader or placeholder
  }

  // Redirect to login if no valid token is present
  if (!token) {
    return <Navigate to="/admin-x9z7q1w3t8" replace />;
  }

  return children;
};

export default ProtectedRoute;
