import React from 'react';
import { Switch, Button } from 'antd';
import AddressEditor from './addressEditor';
import CoordsEditor from './coordEditor';
import { Thumbnail } from '../../common';

export const getColumns = ({
  loading,
  updateAgencyValue,
  handleCopy,
  handleDelete,
  copiedPhone,
  superAdmin,
  setAddress,
}) => [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    width: 60,
    render: id => (
      <div
        style={{
          maxWidth: '60px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          color: '#1890ff',
        }}
        onClick={() => handleCopy(id, null)}
        title={id}
      >
        {id}
      </div>
    ),
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    render: avatar => <Thumbnail src={avatar} alt='Avatar' />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'E-Mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: 120,
    render: (_, data) => {
      const phoneNumber =
        data.verified || !data.placeDetails
          ? data.phone
          : data.placeDetails?.international_phone_number;

      const isCopied =
        copiedPhone &&
        copiedPhone.id === data._id &&
        copiedPhone.phoneNumber === phoneNumber;

      return (
        <div
          style={{
            maxWidth: '120px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            color: isCopied ? '#52c41a' : '#1890ff',
          }}
          onClick={() => handleCopy(phoneNumber, data._id)}
          title={phoneNumber || 'N/A'}
        >
          {phoneNumber || 'N/A'}
        </div>
      );
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (_, data) => (
      <AddressEditor
        agencyId={data._id}
        address={data?.address?.full}
        onUpdate={updateAgencyValue}
        setAddress={setAddress}
      />
    ),
  },
  {
    title: 'Coords',
    dataIndex: 'address',
    key: 'coords',
    render: (_, data) => (
      <CoordsEditor
        agencyId={data._id}
        coordinates={data?.address?.coordinates}
        address={data?.address?.full}
        onUpdate={updateAgencyValue}
      />
    ),
  },
  {
    title: 'Region',
    dataIndex: 'address',
    key: 'region',
    render: address => <span>{address?.region}</span>,
  },
  {
    title: 'Country',
    dataIndex: 'address',
    key: 'country',
    render: address => <span>{address?.country}</span>,
  },
  {
    title: 'Listed',
    dataIndex: 'isListed',
    key: 'isListed',
    render: (_, data) => (
      <Switch
        disabled={loading}
        checked={data.isListed}
        onChange={val =>
          updateAgencyValue({
            agencyId: data._id,
            field: 'isListed',
            value: val,
          })
        }
      />
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, agency) => (
      <Button
        type='primary'
        danger
        disabled={!superAdmin || loading}
        onClick={() => handleDelete(agency)}
      >
        Delete
      </Button>
    ),
  },
];
