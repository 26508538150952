import React from 'react';
import { Input, Button } from 'antd';

const SearchBar = ({ search, setSearch, address, setAddress, fetchCoordinates }) => (
  <div style={{ display: 'flex', gap: 16 }}>
    <Input
      style={{ minWidth: 210 }}
      placeholder="Search agencies"
      value={search}
      onChange={(e) => setSearch(e.target.value.toLowerCase())}
    />
    <div style={{ display: 'flex', minWidth: 300 }}>
      <Input
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="Type an address"
      />
      <Button type="primary" onClick={fetchCoordinates}>
        coord finder
      </Button>
    </div>
  </div>
);

export default SearchBar;
