import React from 'react';
import { Table, Pagination } from 'antd';

const TableSection = ({
  title,
  dataSource,
  columns,
  currentPage,
  total,
  pageSize,
  loading,
  onPageChange,
  onPageSizeChange,
}) => (
  <div>
    <h3>{title}</h3>
    <Table
      rowKey="_id"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      loading={loading}
    />
    <Pagination
      current={currentPage}
      total={total}
      pageSize={pageSize}
      pageSizeOptions={[10, 20, 100]}
      showSizeChanger
      onShowSizeChange={(_, newLimit) => onPageSizeChange(newLimit)}
      onChange={onPageChange}
    />
  </div>
);

export default TableSection;
