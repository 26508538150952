import React, { useState } from 'react';
import { Input, Button, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons'; // Import Ant Design's Edit Icon
import Api from '../../../utils/api';

const CoordsEditor = ({ agencyId, coordinates = [], address, onUpdate }) => {
  const [longitude, setLongitude] = useState(coordinates[0] || '');
  const [latitude, setLatitude] = useState(coordinates[1] || '');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fetchCoordinates = async () => {
    if (!address) {
      notification.warning({
        message: 'Address Required',
        description: 'Address is missing. Cannot fetch coordinates.',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await Api.get('/admin/findCoords', { address });

      const { lng, lat } = response;

      if (lng && lat) {
        setLongitude(lng);
        setLatitude(lat);
        notification.success({
          message: 'Coordinates Fetched',
          description: `Longitude: ${lng}, Latitude: ${lat}`,
          duration: 1,
        });
      } else {
        notification.error({
          message: 'No Coordinates Found',
          description: 'Unable to find coordinates for the provided address.',
        });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch coordinates. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    onUpdate({
      agencyId,
      field: 'address.coordinates',
      value: [parseFloat(longitude), parseFloat(latitude)],
    });
    setIsEditing(false); // Exit edit mode after update
  };

  return (
    <div style={{ maxWidth: 160 }}>
      {!isEditing ? (
        // View Mode: Show coordinates and "Edit" icon
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {latitude && longitude && <p>{`[${longitude}, ${latitude}]`}</p>}
          <EditOutlined
            style={{
              cursor: 'pointer',
              color: '#1890ff',
              fontSize: '16px',
            }}
            onClick={() => setIsEditing(true)}
          />
        </div>
      ) : (
        // Edit Mode: Show input fields and "Fetch Coordinates" / "Update" buttons
        <div>
          <Button
            type='link'
            onClick={fetchCoordinates}
            style={{ marginBottom: '8px' }}
            disabled={loading}
          >
            Fetch Coordinates
          </Button>
          <Input
            value={longitude}
            onChange={e => setLongitude(e.target.value)}
            placeholder='Longitude'
            style={{ marginBottom: '4px' }}
          />
          <Input
            value={latitude}
            onChange={e => setLatitude(e.target.value)}
            placeholder='Latitude'
            style={{ marginBottom: '4px' }}
          />
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              type='primary'
              onClick={handleUpdate}
              disabled={!longitude || !latitude || loading}
            >
              Update
            </Button>
            <Button onClick={() => setIsEditing(false)} disabled={loading}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoordsEditor;
