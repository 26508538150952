import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useAuthContext } from '../../../utils/authContext';

const LoginForm = styled.div`
  max-width: 400px;
  margin: auto;
  width: 100%;
`;

export const Login = () => {
  const navigate = useNavigate();
  const { setToken, setUser, token } = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const login = async () => {
    setLoading(true);
    setError(''); // Clear any previous error

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/loginAdmin`,
        {
          email,
          password,
        }
      );

      const { token, user } = response.data;

      setToken(token);
      setUser(user);
      localStorage.setItem('token', token);
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  // Redirect when token is set
  useEffect(() => {
    if (token) {
      navigate('/admin-x9z7q1w3t8/messages', { replace: true });
    }
  }, [token, navigate]);

  return (
    <LoginForm>
      <Row justify={'center'} gutter={[24, 24]}>
        {error && (
          <Col span={24}>
            <Alert message={error} type='error' showIcon />
          </Col>
        )}
        <Col span={24}>
          <Input
            placeholder='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Input
            type='password'
            placeholder='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Button type='primary' onClick={login} loading={loading}>
            Login
          </Button>
        </Col>
      </Row>
    </LoginForm>
  );
};
