import React, { useState } from 'react';
import { Input, Button, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const AddressEditor = ({ agencyId, address = '', onUpdate, setAddress }) => {
  const [newAddress, setNewAddress] = useState(address || '');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await onUpdate({
        agencyId,
        field: 'address.full',
        value: newAddress,
      });
      notification.success({
        message: 'Update Successful',
        description: 'The address has been updated successfully.',
      });
      setIsEditing(false); // Exit edit mode after update
    } catch (error) {
      console.error('Error updating address:', error);
      notification.error({
        message: 'Update Failed',
        description:
          'There was an error updating the address. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 240 }}>
      {!isEditing ? (
        // View Mode: Show address and "Edit" icon
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          onClick={() => setAddress(address)}
        >
          {newAddress && <>{newAddress}</>}
          <EditOutlined
            style={{
              cursor: 'pointer',
              color: '#1890ff',
              fontSize: '16px',
            }}
            onClick={() => setIsEditing(true)}
          />
        </div>
      ) : (
        // Edit Mode: Show input fields and "Update" / "Cancel" buttons
        <div>
          <Input
            value={newAddress}
            onChange={e => setNewAddress(e.target.value)}
            placeholder='Address'
            style={{ marginBottom: '8px' }}
          />
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              type='primary'
              onClick={handleUpdate}
              disabled={!newAddress || loading}
            >
              Update
            </Button>
            <Button onClick={() => setIsEditing(false)} disabled={loading}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressEditor;
