import React from 'react';
import { Modal } from 'antd';

const ActionModal = ({
  isVisible,
  onConfirm,
  onCancel,
  agencyToDelete,
  loading,
}) => (
  <Modal
    title="Confirm Delete"
    visible={isVisible}
    onOk={onConfirm}
    onCancel={onCancel}
    okText="Yes, Delete"
    cancelText="Cancel"
    confirmLoading={loading}
  >
    <p>
      Are you sure you want to delete agency <strong>{agencyToDelete?.name}</strong>?
    </p>
  </Modal>
);

export default ActionModal;
