import React, { useState, useEffect } from 'react';
import './index.css';
import logo from './assets/images/oceanlabs-logo.png';
import appScreenshot1 from './assets/images/app-screenshot.png';
import appScreenshot2 from './assets/images/app-screenshot2.png';
import appScreenshot3 from './assets/images/app-screenshot3.png';
import appScreenshot4 from './assets/images/app-screenshot4.png';
import { Link } from 'react-router-dom';

const images = [appScreenshot1, appScreenshot2, appScreenshot3, appScreenshot4];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className='landing-page'>
        <div className='content'>
          <div className='app-info'>
            <div className='app-logo'>
              <img src={logo} alt='Ocean Labs Logo' />
            </div>
            <h1>Ocean Labs</h1>
            <p>Your agency in the cloud.</p>
            <p>Shape the future of maritime with us!</p>
            <div className='download-buttons'>
              <a
                className='button ios'
                href='https://apps.apple.com/tr/app/ocean-labs/id6738892255'
                aria-disabled='true'
                aria-label='Download from Google Play Store'
                target='_blank'
                rel='noopener noreferrer'
              />
              <a
                className='button playstore'
                href='https://play.google.com/store/apps/details?id=com.oceanlabs.oceanlabs'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Download from Google Play Store'
              />
            </div>
          </div>
          <div className='phone-display'>
            <div className='carousel'>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`App Screenshot ${index + 1}`}
                  className={`carousel-image ${
                    index === currentIndex ? 'visible' : ''
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='policy-container'>
        <p className='legal'>
          <Link target='_blank' to='/privacy-policy' style={{ color: 'white' }}>
            Privacy Policy
          </Link>
        </p>
        <p className='legal'>
          <Link
            target='_blank'
            to='/terms-and-conditions'
            style={{ color: 'white' }}
          >
            Terms and Conditions
          </Link>
        </p>
        <p className='legal'>
          <Link target='_blank' to='/data-agreement' style={{ color: 'white' }}>
            Data Processing Agreement
          </Link>
        </p>
        <p className='website'>2024 OceanLabs LLC</p>
      </div>
    </>
  );
};

export default LandingPage;
