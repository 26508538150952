import React, { useState, useEffect, useCallback } from 'react';
import { notification } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { useAuthContext } from '../../../utils';
import Api from '../../../utils/api';
import { getColumns } from './columns';
import TableSection from './TableSection';
import SearchBar from './SearchBar';
import ActionModal from './ActionModal';

const StyledTableWrapper = styled.div`
  margin: 0 8px;
  .ant-table-cell {
    padding: 8px 4px !important;
  }
`;

export const AgencyPortal = () => {
  const { token, superAdmin } = useAuthContext();
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [unverifiedAgencies, setUnverifiedAgencies] = useState([]);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [unverifiedCount, setUnverifiedCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [verifiedPage, setVerifiedPage] = useState(1);
  const [unverifiedPage, setUnverifiedPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [copiedPhone, setCopiedPhone] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [agencyToDelete, setAgencyToDelete] = useState(null);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState(null);

  const fetchCoordinates = async () => {
    if (!address) {
      notification.warning({
        message: 'Address Required',
        description: 'Please enter an address to find coordinates.',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await Api.get('/admin/findCoords', { address });
      const { lng, lat } = response;

      if (lng && lat) {
        setCoordinates({ lng, lat });
        notification.success({
          message: 'Coordinates Fetched',
          description: `Longitude: ${lng}, Latitude: ${lat}`,
        });
      } else {
        setCoordinates(null);
        notification.error({
          message: 'No Coordinates Found',
          description: 'Unable to find coordinates for the provided address.',
        });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch coordinates. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateAgencyValue = async ({
    agencyId,
    field,
    value,
    oceanLabsRegistered = false,
  }) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/updateAgency`, {
        agencyId,
        field,
        value,
      });

      const currentData = oceanLabsRegistered
        ? verifiedAgencies
        : unverifiedAgencies;

      const updatedData = currentData.map((item) =>
        item._id === agencyId ? { ...item, [field]: value } : item
      );

      if (oceanLabsRegistered) {
        setVerifiedAgencies(updatedData);
      } else {
        setUnverifiedAgencies(updatedData);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgencies = useCallback(async () => {
    setLoading(true);
    try {
      const verifiedResponse = await Api.get('/admin/getAgencies', {
        page: verifiedPage,
        limit,
        search,
      });

      setVerifiedAgencies(verifiedResponse.verifiedAgencies);
      setVerifiedCount(verifiedResponse.verifiedCount);

      const unverifiedResponse = await Api.get('/admin/getAgencies', {
        page: unverifiedPage,
        limit,
        search,
      });

      setUnverifiedAgencies(unverifiedResponse.unverifiedAgencies);
      setUnverifiedCount(unverifiedResponse.unverifiedCount);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    } finally {
      setLoading(false);
    }
  }, [verifiedPage, unverifiedPage, limit, search]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      fetchAgencies();
    }
  }, [token, fetchAgencies]);

  const handleCopy = (phoneNumber, id) => {
    if (phoneNumber) {
      navigator.clipboard.writeText(phoneNumber);
      setCopiedPhone({ id, phoneNumber });
      setTimeout(() => setCopiedPhone(null), 1000);
    }
  };

  const handleDelete = (agency) => {
    setAgencyToDelete(agency);
    setIsModalVisible(true);
  };

  const confirmDelete = async () => {
    if (!agencyToDelete) return;

    setLoading(true);
    try {
      await Api.delete('/admin/removeUser', {
        _id: agencyToDelete._id,
      });

      notification.success({
        message: 'Agency Deleted',
        description: `Agency ${agencyToDelete.name} was deleted successfully.`,
      });

      if (verifiedAgencies.includes(agencyToDelete)) {
        setVerifiedAgencies((prev) =>
          prev.filter((a) => a._id !== agencyToDelete._id)
        );
        setVerifiedCount((prev) => prev - 1);
      } else {
        setUnverifiedAgencies((prev) =>
          prev.filter((a) => a._id !== agencyToDelete._id)
        );
        setUnverifiedCount((prev) => prev - 1);
      }
    } catch (error) {
      console.error('Error deleting agency:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to delete agency. Please try again later.',
      });
    } finally {
      setIsModalVisible(false);
      setAgencyToDelete(null);
      setLoading(false);
    }
  };

  const columns = getColumns({
    loading,
    updateAgencyValue,
    handleCopy,
    handleDelete,
    copiedPhone,
    superAdmin,
    setAddress
  });

  return (
    <StyledTableWrapper>
      <SearchBar
        search={search}
        setSearch={setSearch}
        address={address}
        setAddress={setAddress}
        fetchCoordinates={fetchCoordinates}
      />
      <TableSection
        title="Verified Agencies"
        dataSource={verifiedAgencies}
        columns={columns}
        currentPage={verifiedPage}
        total={verifiedCount}
        pageSize={limit}
        loading={loading}
        onPageChange={setVerifiedPage}
        onPageSizeChange={setLimit}
      />
      <TableSection
        title="Unverified Agencies"
        dataSource={unverifiedAgencies}
        columns={columns.filter((col) => col.key !== 'coords')}
        currentPage={unverifiedPage}
        total={unverifiedCount}
        pageSize={limit}
        loading={loading}
        onPageChange={setUnverifiedPage}
        onPageSizeChange={setLimit}
      />
      <ActionModal
        isVisible={isModalVisible}
        onConfirm={confirmDelete}
        onCancel={() => setIsModalVisible(false)}
        agencyToDelete={agencyToDelete}
        loading={loading}
      />
    </StyledTableWrapper>
  );
};

export default AgencyPortal;
