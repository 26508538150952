import React from 'react';
import { useAuthContext } from './authContext';

const ProtectedRoute = ({ children }) => {
  const { loading } = useAuthContext();

  // Show a loading placeholder while auth state is being determined
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  // Redirect to login if the user is not authenticated
  // if (!isAuthenticated) {
  //   console.log('Not authenticated. Redirecting to login...');
  //   return <Navigate to="/admin-x9z7q1w3t8" replace />;
  // }

  // Render the protected component if authenticated
  return children;
};

export default ProtectedRoute;
