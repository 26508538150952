import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({
  user: {},
  token: '',
  isAuthenticated: false,
  loading: true,
  setUser: () => {},
  setToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(token));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const superAdmin = user?.email === 'oooytun@icloud.com';

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      localStorage.setItem('token', token);
    } else {
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('token');
      setIsAuthenticated(false);
    }
  }, [token]);

  useEffect(() => {
    const loginSilently = async () => {
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/loginWithToken`,
            { token }
          );

          console.log('API Response:', response.data);

          if (response?.data === 'Oopsy!') {
            console.log('Token invalid or expired. Logging out...');
            setIsAuthenticated(false);
            setToken('');
            navigate('/admin-x9z7q1w3t8');
          } else if (response?.data?.user) {
            setUser(response.data.user);
            setIsAuthenticated(true);
          } else {
            console.error('Unexpected API response:', response.data);
            setToken('');
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Silent login failed:', error);
          setToken('');
          setIsAuthenticated(false);
          navigate('/admin-x9z7q1w3t8');
        }
      }
      setLoading(false);
    };

    loginSilently();
  }, [token, navigate]);

  return (
    <AuthContext.Provider
      value={{ user, setUser, token, setToken, isAuthenticated, loading, superAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
