import { AgencyPortal, Login, Users } from '../components/ContentPages';
import { ArtificialIntelligence } from '../components/ContentPages/ArtificialIntelligence';
import Broadcasting from '../components/ContentPages/Broadcasting';
import { OfferPortal } from '../components/ContentPages/OfferPortal';

export const routes = [
  { text: 'Domain', url: '/', key: '/', page: <Login />, icon: 'Domain' },
  {
    text: 'Open Jobs',
    url: '/admin-x9z7q1w3t8/open-jobs',
    key: 'open-job',
    icon: 'Work',
    page: <OfferPortal />,
  },
  {
    text: 'Agency Portal',
    url: '/admin-x9z7q1w3t8/agency-portal',
    key: '/agency-portal',
    icon: 'Account_Balance',
    page: <AgencyPortal />,
  },
  {
    text: 'Messages',
    url: '/admin-x9z7q1w3t8/messages',
    key: '/messages',
    icon: 'Message',
    page: <Users />,
  },
  {
    text: 'Broadcasting',
    url: '/admin-x9z7q1w3t8/broadcasting',
    key: '/broadcasting',
    icon: 'Cast_Connected',
    page: <Broadcasting />,
  },
  {
    text: 'AI',
    url: '/admin-x9z7q1w3t8/artificial-intelligence',
    key: '/artificial-intelligence',
    icon: 'Smart_Toy',
    page: <ArtificialIntelligence />,
  },
];
