import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { theme as antdTheme, ConfigProvider } from 'antd';
import { theme } from './components/common/theme';
import { routes } from './utils';
import { AuthProvider } from './utils/authContext';
import { ThemeProvider } from 'styled-components';
import { PageLayout } from './components/common/PageLayout';
import { GlobalCss } from './components/common/Global';
import LandingPage from './pages/LandingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import DataProcessingAgreement from './pages/DataProcessingAgreement';
import RemoveUser from './pages/RemoveUser';
import { Login } from './components/ContentPages';
import ProtectedRoute from './utils/ProtectedRoute';

function AppContent() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/' element={<LandingPage />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='/data-agreement' element={<DataProcessingAgreement />} />
      <Route path='/remove-user' element={<RemoveUser />} />
      <Route path='/admin-x9z7q1w3t8' element={<Login />} />
      <Route path='*' element={<div>404 - Page Not Found</div>} />

      {/* <Route path="/admin-x9z7q1w3t8" element={<RemoveUser />} /> */}

      {/* Protected Routes with PageLayout */}
      {routes.map(path => (
        <Route
          path={path.url}
          element={
            <ProtectedRoute>
              <PageLayout
                staticHeight
                hideNavbar
                title='OceanLabs'
                sideBarItems={routes}
              >
                {path.page}
              </PageLayout>
            </ProtectedRoute>
          }
          key={path.url}
        />
      ))}
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider theme={{ algorithm: antdTheme.darkAlgorithm }}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <div className='App'>
              <AppContent />
            </div>
          </ThemeProvider>
        </ConfigProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
